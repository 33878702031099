import React from 'react';
import CompanyDescription from '../components/About/CompanyDescription';
import Values from '../components/About/Values';

import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css';         
import 'primeicons/primeicons.css';                       
import '../style pages/About.css';                                




const About = () => {

    return (
        <div className="about_container">
            
            <CompanyDescription/>
            <Values/>
            
        </div>
    );
};

export default About;
