import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import it from './locales/it.json';
import en from './locales/en.json';


i18n
  .use(LanguageDetector) // Rileva la lingua del browser
  .use(initReactI18next) // Inizializza React
  .init({
    resources: {
      "it": { "translation": it },
      "en": { "translation": en },
    },
    lng: 'it',
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
