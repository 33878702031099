import React from 'react';
import "./style/FeaturesCards.css";

// Icone temporanee
const featureData = [
    {
        icon: "https://via.placeholder.com/50", 
        title: "Speed Boost",
        description: "Concurrently update real-time data while maximizing computational efficiency for enhanced speed."
    },
    {
        icon: "https://via.placeholder.com/50",
        title: "Multitasking Management",
        description: "A substantial data capacity facilitates the handling of multiple platforms under a single account."
    },
    {
        icon: "https://via.placeholder.com/50",
        title: "Integrate",
        description: "The entirety of the data is seamlessly integrated, yielding clear and comprehensive aggregated outcomes."
    }
];

const FeatureData = () => {
    return (
        <div className="features-cards-container">
            {featureData.map((feature, index) => (
                <div className="feature-card" key={index}>
                    <div className="icon-container">
                        <img src={feature.icon} alt="Icon" className="feature-icon" />
                    </div>
                    <h3 className="feature-title">{feature.title}</h3>
                    <p className="feature-description">{feature.description}</p>
                    <button className="explore-button">Explore Features</button>
                </div>
            ))}
        </div>
    );
};

export default FeatureData;
