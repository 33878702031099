import React from 'react';
import './style/InsightSection.css';


const InsightSection = () => {
    return (
        <div className="insights-section">
            <div className="insights-header">
                <h2>Real-Time</h2>
                <h3>Insights Unlocked</h3>
            </div>
            <div className="insights-content">
                {/* Immagini */}
                <div className="image-grid">
                    <div className="image-large">
                        <img src="https://via.placeholder.com/500x300" alt="Main Insight" />
                    </div>
                    <div className="image-small-container">
                        <div className="image-small">
                            <img src="https://via.placeholder.com/200x150" alt="Insight 1" />
                        </div>
                        <div className="image-small">
                            <img src="https://via.placeholder.com/200x150" alt="Insight 2" />
                        </div>
                    </div>
                </div>
                {/* Descrizione */}
                <div className="insights-description">
                    <p>
                        Overview of data fluctuations. Examining and understanding the variations and changes in data over a specific period.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default InsightSection;
