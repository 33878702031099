import React from "react";
import "../components/PrivacyPolicy/style/PrivacyPolicy.css";

const PrivacyPolicyRicontattoPage = () => {
  return (
    <div className="data-mastery-container">
      <article>
        <header>
          <h1>Privacy policy del modulo di ricontatto</h1>
          <p>Ultimo aggiornamento: 9 dicembre 2024</p>
        </header>

        <section>
          <p>
            Grazie per aver visitato l'Informativa sulla Privacy del modulo di
            ricontatto di Se.Me.Te SRL. Questa
          </p>
          <p>
            informativa spiega come Se.Me.Te SRL (di seguito "Se.Me.Te SRL",
            "noi", "ci" o "nostro/a"){" "}
          </p>
          <p>
            raccoglie, utilizza e condivide informazioni personali che ti
            riguardano (di seguito "utente") quando{" "}
          </p>
          <p>inserisci i tuoi dati nel modulo di ricontatto.</p>
        </section>

        <section>
          <h2>1. Titolare del Trattamento</h2>
          <p>Il Titolare del trattamento dei dati è: Se.Me.Te SRL</p>
          <p>Via Benedetto Croce, 8</p>
          <p>95039 Trecastagni (CT)</p>
          <p>
            Email: <a href="mailto:devsemete@gmail.com">devsemete@gmail.com</a>
          </p>
        </section>

        <section>
          <h2>2. Tipologie di Dati Raccolti nel modulo di ricontatto</h2>
          <p>Nome dell’utente;</p>
          <p>Indirizzo e-mail per il ricontatto;</p>
          <p>Numero di telefono</p>
          <p>Tipo di richiesta;</p>
          <p>Corpo del messaggio inviato a devsemete@gmail.com</p>
        </section>

        <section>
          <h2>3. Finalità del trattamento</h2>
          <p>
            I dati raccolti nel modulo di ricontatto saranno esclusivamente
            utilizzati per rispondere alla richiesta dell’utente.
          </p>
        </section>

        <section>
          <h2>4. Destinatari dei dati personali</h2>
          <p>
            I dati raccolti da noi tramite il modulo di ricontatto saranno
            trattati esclusivamente da personale interno di Se.Me.Te SRL, ad
            eccezione che la condivisione degli stessi sia richiesta dalla
            legge.
          </p>
        </section>

        <section>
          <h2>5. Periodo di Conservazione dei Dati</h2>
          <p>
            Conserviamo i tuoi dati solo per il tempo strettamente necessario a
            soddisfare le finalità per cui sono stati raccolti, ossia rispondere
            alla richiesta dell’utente.
          </p>
        </section>

        <section>
          <h2>6. Diritto di accesso</h2>
          <p>
            L’utente ha il diritto di ottenere dal titolare del trattamento la
            conferma che sia o meno in corso un trattamento di dati personali
            che lo riguardano e in tal caso, di ottenere l’accesso ai dati
            personali e alle seguenti informazioni:
          </p>
          <ul>
            <li>Le finalità del trattamento;</li>
            <li>Le categorie di dati personali in questione;</li>
            <li>
              I destinatari o le categorie di destinatari a cui i dati personali
              sono stati o saranno comunicati;
            </li>
            <li>I diritti esplicati in questo documento</li>
          </ul>
        </section>

        <section>
          <h2>6. Trasferimenti di Dati all'Estero</h2>
          <p>
            I dati inseriti attraverso il modulo di ricontatto non saranno
            inviati a paesi terzi o organizzazioni internazionali.
          </p>
        </section>

        <section>
          <h2>7. Diritto alla cancellazione e rettifica dei dati</h2>
          <p>
            L’utente ha il diritto di richiedere in qualsiasi momento inviando
            una mail a{" "}
            <a href="mailto:devsemete@gmail.com">devsemete@gmail.com</a> di
            cancellare o rettificare i propri dati.
          </p>
        </section>

        <section>
          <h2>8. Modifiche alla Privacy Policy</h2>
          <p>
            Potremmo aggiornare questa informativa periodicamente. Ti invitiamo
            a consultare questa pagina regolarmente.
          </p>
        </section>

        <section>
          <h2>9. Limitazione del trattamento</h2>
          <p>
            L’utente ha il diritto di ottenere dal titolare del trattamento la
            limitazione del trattamento quando ricorre una delle seguenti
            ipotesi:
          </p>
          <ul>
            <li>
              L’utente contesta l’esattezza dei dati personali, per il periodo
              necessario al titolare del trattamento per verificare l’esattezza
              di tali dati personali;
            </li>
            <li>
              Il trattamento è illecito e l’utente si oppone alla cancellazione
              dei dati personali e chiede invece che ne sia limitato l’utilizzo;
            </li>
            <li>
              Benché il titolare del trattamento non ne abbia più bisogno ai
              fini del trattamento, i dati personali sono necessari all’utente
              per l’accertamento, l’esercizio o la difesa di un diritto in sede
              giudiziaria;
            </li>
            <li>
              L’utente si è opposto al trattamento ai sensi dell’articolo 21,
              paragrafo 1, in attesa della verifica in merito all’eventuale
              prevalenza dei motivi legittimi del titolare del trattamento
              rispetto a quelli dell’interessato.
            </li>
          </ul>
          <p>
            Qualora l’utente volesse esercitare il diritto di limitazione dei
            dati, potrà inviare una mail a{" "}
            <a href="mailto:devsemete@gmail.com">devsemete@gmail.com</a>
          </p>
        </section>

        <section>
          <h2>10. Diritto di reclamo</h2>
          <p>
            L’utente ha diritto di proporre reclamo presso l’autorità di
            controllo nello stato membro cui risiede abitualmente, lavora,
            oppure nel luogo ove si è verificata la presunta violazione.
          </p>
        </section>

        <section>
          <p>
            Per l’informativa completa relativa ai dati personali raccolti dal
            sito si rimanda alla pagina{" "}
            <a href="/privacy-policy">privacy policy</a>.
          </p>
        </section>
      </article>
    </div>
  );
};

export default PrivacyPolicyRicontattoPage;
