import React from 'react';
import { useTranslation } from "react-i18next";
import '../../style/Values.css'; 

const Values = () => {
  const { t } = useTranslation();

  const values = [
    { key: '0' },
    { key: '1' },
    { key: '2' },
    { key: '3' },
  ];

  return (
    <div className="values-section">
      <h2 className='title_about'>{t('value.title.about')}</h2>
      <div className="values-container">
        {values.map((value, index) => (
          <div key={index} className="value-card">
            <h3>{t(`value.cards.${value.key}.title`)}</h3>
            <p>{t(`value.cards.${value.key}.description`)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Values;
