import React, { useState } from "react";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import FormInfo from "../FormInfo";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./style/Presentation.css";


import iconSup from "../../asset/iconSup.svg";
import iconAs from "../../asset/iconAs.svg";
import iconIT from "../../asset/iconIT.svg";
import iconSite from "../../asset/iconSite.svg";
import iconApp from "../../asset/iconApp.svg";


const Presentation = () => {
  const [category /* setCategory */] = useState("IT");
  const [displayContact, setDisplayContact] = useState(false);
  const [animating /* setAnimating */] = useState(false); // Stato per l'animazione
  const { t } = useTranslation();

  const categories = {
    IT: {
      title: t("titles.welcome-to-Se.Me.Te"),
      description:
        t("subtitle.offer-services"),
      descriptionColor: "#ffffff",
      services: [
        {
          title: t("card.support"),
          description:
            t("desCard.support"),
          icon: iconSup,
        },
        {
          title: t("card.assistance"),
          description:
            t("desCard.assistance"),
          icon: iconAs,
        },
        {
          title: "IT Solution",
          description:
            t("desCard.solution"),
          icon: iconIT,
        },
        {
          title: t("card.website"),
          description:
            t("desCard.website"),
          icon: iconSite,
        },
        {
          title: "App Dev",
          description:
            t("desCard.dev"),
          icon: iconApp,
        },
      ],
      colorClass: "blue-theme", 
    },
  };

  const currentCategory = categories[category];
  const closeContactForm = () => setDisplayContact(false);

  return (
    <div
      className={`presentation-container ${currentCategory.colorClass} ${
        animating ? "animating" : ""
      }`}
    >
      <div className="top_descrbtn">
        <h2 className="presentation-title">{currentCategory.title}</h2>
      </div>
      <div className="top_descrbtn">
        <p
          className="presentation-description"
          style={{ color: currentCategory.descriptionColor }}
        >
          {currentCategory.description}
        </p>
      </div>

      <div className={`card-container ${animating ? "animating" : ""}`}>
        {currentCategory.services.map((service, index) => (
          <Card
            key={index}
            title={service.title}
            className="card"
            style={{ width: "250px", margin: "10px" }}
          >
            <div
              style={{
                fontSize: "40px",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <img
                src={service.icon}
                alt={service.title}
                style={{ width: "40px", height: "40px" }}
              />
            </div>
            <p>{service.description}</p>
          </Card>
        ))}
      </div>
      <Link to="/supporto" className="contact-button">
        <span>{t('card.linkform')}</span>
      </Link>

      <Dialog
        visible={displayContact}
        style={{ width: "450px" }}
        className="container_dialog"
        modal
        onHide={closeContactForm}
      >
        <FormInfo />
      </Dialog>
    </div>
  );
};

export default Presentation;
