import React from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './style/Usefulness.css';
import i18n from "../../i18n"
import { useTranslation } from "react-i18next";


import Img1 from "../../asset/Img1.png";
import Img2 from "../../asset/Img2.png";
import Img3 from "../../asset/Img3.png";
import Img4 from "../../asset/Img4.png";



const Usefulness = () => {
    const { t } = useTranslation();

    return (
        <div className="background-gray">
            <div className="container_grid">
                <div className="first_item"
                    style={{
                        backgroundImage: `url(${Img1})`, // Usa il logo come sfondo
                        backgroundSize: 'contain', // Adatta l'immagine alle dimensioni del contenitore
                        backgroundPosition: 'center', // Centra l'immagine
                        backgroundRepeat: 'no-repeat', // Evita ripetizioni
                    }}>
                    <h3 className='title_carduse'>{t('titles.usefulnesCard1')}</h3>
                    <p className='descrip_carduse'>{t('desUsefulnes.cardOne')}</p>
                </div>


                <div className="second_item" style={{
                    backgroundImage: `url(${Img2})`, // Usa il logo come sfondo
                    backgroundSize: 'contain', // Adatta l'immagine alle dimensioni del contenitore
                    backgroundPosition: 'center', // Centra l'immagine
                    backgroundRepeat: 'no-repeat', // Evita ripetizioni
                }}>
                    <h3 className='title_carduse'>{t('titles.usefulnesCard2')}</h3>
                    <p className='descrip_carduse'>{t('desUsefulnes.cardTwo')}</p>
                </div>


                <div className="third_item"
                    style={{
                        backgroundImage: `url(${Img3})`, // Usa il logo come sfondo
                        backgroundSize: 'contain', // Adatta l'immagine alle dimensioni del contenitore
                        backgroundPosition: 'center', // Centra l'immagine
                        backgroundRepeat: 'no-repeat', // Evita ripetizioni
                    }}>
                    <h3 className='title_carduse'>{t('titles.usefulnesCard3')}</h3>
                    <p className='descrip_carduse'>{t('desUsefulnes.cardThree')}</p>
                </div>


                <div className="four_item"
                    style={{
                        backgroundImage: `url(${Img4})`, // Usa il logo come sfondo
                        backgroundSize: 'contain', // Adatta l'immagine alle dimensioni del contenitore
                        backgroundPosition: 'center', // Centra l'immagine
                        backgroundRepeat: 'no-repeat', // Evita ripetizioni
                    }}>
                    <h3 className='title_carduse'>{t('titles.usefulnesCard4')}</h3>
                    <p className='descrip_carduse'>{t('desUsefulnes.cardFour')} </p>
                </div>
            </div>

        </div>
    );
};

export default Usefulness;
