import React, { useState } from "react";
import "./SupportPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import i18n from "../../i18n"
import { useTranslation } from "react-i18next";

const SupportPage = () => {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    indirizzo: "",
    cellulare: "",
    problema: "",
    opzione: "",
  });
  const { t } = useTranslation();

  // Gestione input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Funzione per inviare il form
  const handleSubmit = async () => {
    try {
      console.log("Invio questi dati");
      console.log(JSON.stringify(formData));
      const response = await fetch(
        "https://www.semete.it/servizi/send-email.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const result = await response.text();
        alert(result);
      } else {
        alert("Errore durante l'invio del messaggio.");
      }
    } catch (error) {
      console.error("Errore durante la richiesta:", error);
      alert("Errore durante la connessione al server.");
    }
  };

  return (
    <div className="support-container">
      <div className="support-page">
        <div className="support-header">
          <h2>{t('support.title.about')}</h2>
          <p>
            {t('support.description.help')}
          </p>
        </div>
      </div>
      {/* Sezione sinistra - Form */}
      <div className="form-org">
        <div className="support-left">
          <form className="support-form">
            <div className="form-group">
              <label htmlFor="name">{t('support.form.name')}</label>
              <input
                type="text"
                id="nome"
                placeholder="Es: Mario"
                name="nome"
                value={formData.nome}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Es: utente@semete.it"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">{t('support.form.number')}</label>
              <input
                type="tel"
                id="phone"
                name="cellulare"
                placeholder="+39 123 456 7890"
                value={formData.cellulare}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="request">{t('support.form.request')}</label>
              <select
                id="request"
                name="opzione"
                value={formData.opzione}
                onChange={handleChange}
              >
                <option value="">{t('support.form.sector')}</option>
                <option value="support">{t('navigation.support')}</option>
                <option value="sales">{t('support.form.selling')}</option>
                <option value="other">{t('support.form.other')}</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="message">{t('support.form.message')}</label>
              <textarea
                id="message"
                placeholder="Come possiamo aiutarti?"
                name="problema"
                value={formData.problema}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-group">
              <label className="checkbox-container">
                <input type="checkbox" id="consent" />
                <span>
                  {t('support.form.regulation')} <br />
                  <a href="/privacy-policy-form-ricontatto" target="_blank">
                    {" "}
                    Privacy policy
                  </a>
                </span>
              </label>
            </div>
            <button type="button" onClick={handleSubmit} className="submit-btn">
              <FontAwesomeIcon icon={faPaperPlane} /> {t('support.form.send')}
            </button>
          </form>
        </div>

        {/* Sezione destra - Informazioni */}
        <div className="support-right">
          <div className="contact-info">
            <h3>{t('support.description.call')}</h3>
            {/* <p>Dal lun-ven dalle 9:00 alle 18:00</p> */}
            <p>
              <FontAwesomeIcon icon={faPhoneVolume} /> +39 349 149 8790
            </p>
          </div>
          <div className="social-info">
            <h3>{t('support.description.chat')}</h3>
            <p>{t('support.description.find')}</p>
            <p>
              <a
                href="https://www.linkedin.com/company/se-me-te-srl/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('support.description.social')}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;
