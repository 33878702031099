import React from "react";
import { Button } from "primereact/button";
import "../style/Footer.css";
import LogoImg from "../asset/Logo.png";
import i18n from "../i18n.jsx"
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  
  // Funzione per scorrere in alto
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Funzione per aprire il link LinkedIn
  const openLinkedIn = () => {
    window.open(
      "https://www.linkedin.com/company/se-me-te-srl/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <footer className="footer">
      <div className="footer-top">
        {/* Logo e descrizione */}
        <div className="footer-logo">
          <img src={LogoImg} alt="Company Logo" />
          <p>Via Benedetto Croce, 8, 95039, Trecastagni (CT)</p>
          <p>P.IVA: 05862030870</p>
        </div>

        <div className="footer-links">
          <div className="footer-left">
            <div className="footer-section">
              <h4 className="title">{t('footer.title.about')}</h4>
              <a href="/about">{t('footer.link.tech')}</a>
              <br />
              <a href="/supporto">{t('footer.link.sup')}</a>
            </div>
          </div>

          {/* Sezione Destra */}
          <div className="footer-right">
            <div className="footer-section">
              <h4 className="title">{t('footer.title.contact')}</h4>
              <p>E-mail: devsemete@gmail.com</p>
              <p>{t('footer.link.phone')}: +39 349 149 8790</p>
            </div>
          </div>
        </div>

        <div className="footer-social">
          <p className="title_social">{t('footer.title.follow')}</p>
          <a href="https://www.linkedin.com/company/se-me-te-srl/">
            <i className="pi pi-linkedin"></i>
          </a>
        </div>
      </div>

      {/* Parte inferiore del footer */}
      <div className="footer-bottom">
        <p>
          &copy; 2024 Se.Me.Te SRL {t('footer.link.royalties')}.
          <br /> <a href="/privacy-policy">Privacy Policy</a>
        </p>
      </div>

      {/* Bottone per tornare in alto */}
      <Button
        icon="pi pi-arrow-up"
        className="scroll-top-btn"
        onClick={scrollToTop}
        aria-label="Torna su"
      />
    </footer>
  );
};

export default Footer;
